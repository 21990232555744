import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/retail ind hs image.svg'
import Logo_img1 from './Svg_files/payment-terminal 1.svg';
import Logo_img2 from './Svg_files/shipping (2).svg';
import Logo_img3 from './Svg_files/crm 1.svg';
import Logo_img4 from './Svg_files/sales 1.svg';

import Production from './Svg_files/utilization (1).svg'
import Operation from "./Svg_files/loyal-customer.svg";
import Quality from './Svg_files/expenses.svg'
import resource from './Svg_files/growth.svg'

import Analytics from './Svg_files/retail ind ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Unified Point of Sale (POS) System", subtext: "Centralizes all sales transactions across stores, ensuring consistent data, reducing errors, and improving checkout efficiency for a seamless shopping experience.", logo: Logo_img1 },
        { id: 2, heading: "Seamless Inventory Management", subtext: "Real-time tracking and management of stock levels across locations to reduce stockouts, overstock, and ensure efficient inventory control.", logo: Logo_img2 },
        { id: 3, heading: "Customer Relationship Management (CRM)", subtext: "Manage customer data and preferences to enhance personalized marketing, customer service, and loyalty programs, driving repeat business.", logo: Logo_img3 },
        { id: 4, heading: "Automated and Customizable Sales Reporting", subtext: "Provides real-time, customizable sales reports for quick analysis, identifying trends, and making data-driven business decisions.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Centralized Management for Multiple Locations", subtext: "Streamlines management of multiple store operations, providing a unified view of sales, inventory, and customer data." },
        { id: 2, heading: "Real-Time Insights into Sales and Inventory", subtext: "Access to real-time data enables quick decision-making on promotions, stock levels, and sales strategies." },
        { id: 3, heading: "Enhanced Customer Engagement Tools", subtext: "Utilize CRM features to personalize marketing campaigns and improve customer satisfaction and loyalty." },
        { id: 4, heading: "Automated Reports for Quick Decision-Making", subtext: "Automated reporting simplifies performance tracking, enabling faster strategic adjustments based on accurate data." }
    ],
    benefitsCards: [
        { id: 1, heading: "Improved Inventory Turnover", subtext: "Optimizes stock levels, reducing excess inventory and enhancing stock availability, leading to better sales.", image: Production },
        { id: 2, heading: "Enhanced Customer Loyalty", subtext: "Personalized service and targeted promotions help retain customers and encourage repeat purchases.", image: Operation },
        { id: 3, heading: "Streamlined Store Operations", subtext: "Centralized management and automation reduce administrative tasks and improve operational efficiency.", image: Quality },
        { id: 4, heading: "Increased Sales and Profitability", subtext: "Better inventory management, customer engagement, and strategic decisions lead to higher sales and profitability.", image: resource }
    ]
};

function Retail() {
    const questions = [
        { question: 'Can Compreo manage loyalty programs?', answer: "Yes, Compreo's CRM module supports managing customer loyalty programs, offering personalized rewards based on purchasing behavior." },
        { question: ' Does Compreo support multiple currencies?', answer: 'Yes, Compreo can handle transactions in multiple currencies, making it ideal for retailers with international operations.' },
        { question: 'How does Compreo help reduce stockouts? ', answer: 'Real-time inventory management and automated replenishment systems prevent stockouts by maintaining optimal stock levels.' },
        { question: 'Can Compreo integrate with e-commerce platforms? ', answer: 'Yes, Compreo integrates with various e-commerce platforms, providing a seamless online and offline shopping experience.' },
        { question: 'Does Compreo offer mobile support for sales tracking? ', answer: "Yes, Compreo's mobile support allows real-time sales tracking and reporting from any device." },
        { question: 'How does Compreo handle discount and promotion management? ', answer: 'Compreo provides tools to manage discounts and promotions centrally, ensuring consistency across all channels and locations.' }
    ];
    return (
        <div className="industry-parent">
           <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">
                       
                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                        <div className="industry-your-future-text-heading">
                        RETAIL IMDUSTRY                        </div>
                        <div className="industry-your-future-text-subtext">
                        "Revolutionize Retail Operations with Powerful ERP Solutions"                        </div>
                        </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                        Compreo ERP helps retailers manage inventory, track sales, and understand customer behavior, providing the tools needed to enhance customer experiences and increase profitability.                       </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Retail</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">
                   
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                               
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default Retail;
